import React, { useState } from 'react';
import { Container } from 'components/common';
import usePresentation from 'react-use-presentation';
import { Wrapper, Flex, Links, Details, PaladinContainer } from './styles';
import social from './social.json';
import { FirstPaladin, paladinMovements } from './paladin';

function PaladinAttacker({ startTrigger = false, callback }) {
  const [Presentation] = usePresentation({
    framesOptions: paladinMovements,
    startTrigger,
    callback,
  });

  return <Presentation />;
}

export const Footer = () => {
  const [startTrigger, setStartTrigger] = useState(false);

  const handleOnKeyDown = e => {
    e.persist();

    if (e.key === 'Enter') {
      setStartTrigger(() => true);
    }
  };

  return (
    <Wrapper>
      <Flex as={Container}>
        <Details>
          <a role="button" tabIndex={0} onKeyDown={handleOnKeyDown} onClick={() => setStartTrigger(true)}>
            <h2>Olavo Parno</h2>
          </a>
          <PaladinContainer onClick={() => setStartTrigger(true)}>
            {startTrigger ? (
              <PaladinAttacker startTrigger={startTrigger} callback={() => setStartTrigger(false)} />
            ) : (
              <FirstPaladin />
            )}
          </PaladinContainer>
        </Details>
        <Links>
          {social.map(({ id, name, link, icon }) => (
            <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
              <img width="24" src={icon} alt={name} />
            </a>
          ))}
        </Links>
      </Flex>
    </Wrapper>
  );
};
