import React, { useCallback, useEffect, useState } from 'react';
import arrowUpward from 'assets/icons/arrowup.svg';
import { FloatButtonContainer } from './styles';

export function FloatingButton() {
  const [buttonStyle, setButtonStyle] = useState({
    display: 'none',
  });

  const matches = typeof window !== 'undefined' && window.innerWidth < 600;

  useEffect(() => {
    function scrollFunction() {
      if (document.body.scrollTop > 630 || document.documentElement.scrollTop > 630) {
        if (matches) {
          setButtonStyle({ display: 'block' });
        }
      } else {
        setButtonStyle({ display: 'none' });
      }
    }

    document.addEventListener('scroll', scrollFunction);

    return () => {
      document.removeEventListener('scroll', scrollFunction);
    };
  }, [matches]);

  const scrollTop = useCallback(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <FloatButtonContainer id="float-button" style={buttonStyle} onClick={scrollTop}>
      <img src={arrowUpward} alt="go-top" />
    </FloatButtonContainer>
  );
}
