module.exports = {
  defaultTitle: "Olavo Parno's Portfolio",
  logo: 'https://olavoparno.vercel.app/favicon.png',
  author: 'Olavo Parno',
  url: 'https://olavoparno.vercel.app',
  legalName: 'Olavo Parno',
  defaultDescription: "My name's Olavo and I'm a Front-End Engineer from Brazil.",
  socialLinks: {
    github: 'https://github.com/olavoparno',
    linkedin: 'https://www.linkedin.com/in/olavoparno/',
    youtube: 'https://www.youtube.com/user/olavoparno',
  },
  googleAnalyticsID: 'UA-85777700-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'olavoparno',
  },
  address: {
    city: 'São Paulo',
    region: 'SP',
    country: 'Brazil',
    zipCode: '01514000',
  },
  contact: {
    email: 'olavoparno@gmail.com',
  },
  foundingDate: '2021',
};
