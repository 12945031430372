import styled from 'styled-components';

export const FloatButtonContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 4rem;
  transform: rotate(180deg);

  & > * {
    margin: 10px;
  }
`;
