import React from 'react';
import paladinSprite from 'assets/paladin-cropped.gif';

export const FirstPaladin = () => (
  <div style={{ height: '48px' }}>
    <img
      src={paladinSprite}
      alt=""
      style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.93rem, 0)' }}
    />
  </div>
);

export const paladinMovements = [
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.93rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.80rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.67rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.535rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.40rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.265rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(0.13rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.004rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.136rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.268rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.402rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.535rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
  {
    component: (
      <div style={{ height: '48px' }}>
        <img
          src={paladinSprite}
          alt=""
          style={{ verticalAlign: 'bottom', transform: 'scale(32.5) scaleY(1.1) translate(-0.67rem, 0)' }}
        />
      </div>
    ),
    time: 50,
  },
];
