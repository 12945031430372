import styled from 'styled-components';

export const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
  margin: 0 0.5rem 0 auto;

  &:focus {
    outline: none;
    transition: 0.3s all;
  }

  @media (max-width: 960px) {
    text-align: left;
  }

  img {
    margin-bottom: unset;
  }

  @media (max-width: 960px) {
    position: absolute;
    top: 1.3rem;
    right: 4rem;
  }
`;
